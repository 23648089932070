import * as React from "react"
import {Link} from 'gatsby'
import Fade from 'react-reveal/Fade';
import Logo from '../images/logos/5thStrand-Cutout-White.svg'
import Layout from '../components/layout'
import PinkFullCircle from '../images/pink-full-circle.svg'
import YellowFullCircle from '../images/yellow-full-circle.svg'
import YellowFull from '../images/yellow-full.svg'
import CyanFull from '../images/cyan-full.svg'
import TransparentCircleOne from '../images/transparent-circle-one.svg'
import TransparentCircleTwo from '../images/transparent-circle-two.svg'
import TransparentCircleThree from '../images/transparent-circle-three.svg'
import TransparentCircleFour from '../images/transparent-circle-four.svg'
import WhiteMoon from '../images/white-moon.svg'

import HTMLlogo from '../images/logos/html.svg'
import csslogo from '../images/logos/css.svg'
import jslogo from '../images/logos/js.svg'
import wordpresslogo from '../images/logos/wordpress.svg'
import reactlogo from '../images/logos/react.svg'
import gatsbylogo from '../images/logos/gatsby.svg'
import drupallogo from '../images/logos/drupal.svg'
import tailwindlogo from '../images/logos/tailwind.svg'
import webflowlogo from '../images/logos/webflow.svg'

import ancienteast from '../images/partners/ancient-east.svg'
import bcc from '../images/partners/BCC.svg'
import creativeeu from '../images/partners/creative-eu.svg'
import derry from '../images/partners/derry.svg'
import portview from '../images/partners/portview.svg'
import publichealth from '../images/partners/public-health.svg'
import wcc from '../images/partners/WCC.svg'


import CustomCursor from 'custom-cursor-react';
import 'custom-cursor-react/dist/index.css';

var ReactRotatingText = require('react-rotating-text');

const IndexPage = () => {
  return (

    <Layout>
      <CustomCursor
      targets={['.link', '.your-css-selector']}
      customClass='custom-cursor'
      dimensions={50}
      fill='#FFD600'
      opacity={1}
      smoothness={{
        movement: 0.2,
        scale: 0.1,
        opacity: 1,
      }}
      targetOpacity={0.5}
    />
    <main className="cont bg-5th-grey nocursor scroll-smooth	">
      <div className="child sm:text-center flex justify-center items-center relative">
        <div className="w-5/6">
          <h1 className=" font-bold text-white text-5xl lg:text-8xl font-heading pb-6">
          SOMETHING NEW <span className="text-5th-pink">?</span><br/>
          WE CAN DO THAT <span className="text-5th-pink">.</span>
          </h1>
          <p className="text-xl lg:text-3xl text-5th-yellow font-heading">We do digital transformations for <br/> <ReactRotatingText items={['historic locations .', 'cultural spaces .', 'health platforms .', ' local leaders .', 'people .']} /></p>
        </div>

        <div className="top-0 left-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={PinkFullCircle}/>
        </div>

        <div className="top-0 right-0 absolute w-24 md:w-36">
          <img className="w-full transition ease-in-out hover:-translate-y-8 duration-300" src={YellowFullCircle}/>
        </div>

        <div className="bottom-10 right-10 absolute ">
          <p className="text-5th-pink">PART OF THE <a target="_blank" href="https://urbanscaleinterventions.com/"><span className="link text-white">USI</span></a> FAMILY</p>
        </div>
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2">
          <div className="text-white font-lower">
            <h3 className="text-5th-yellow text-xl lg:text-3xl pb-4 font-heading">We are</h3>
            <h2 className="text-4xl lg:text-6xl pb-6 font-heading">BELIEVERS IN CHANGE <span className="text-5th-pink">.</span></h2>
            <p className="text-md lg:text-xl z-10">
            Changing, evolving, transforming.    
            All of these describe what we do but the main thing to know is we are people who understand the digital landscape. We should do, we spend a lot of time&nbsp;online!<br/><br/>
            5th Strand combines the experience and core values of our parent company USI with technical expertise and a focus on transforming digital spaces.
            </p>
          </div>

        </div>

        <div className="bottom-0 right-0 absolute w-24 md:w-96 z-10">
          <Fade duration="2000">
            <img className="w-full" src={TransparentCircleOne}/>
          </Fade>
        </div>

        <div className="bottom-12 right-4 absolute w-20 md:w-80 z-20">
          <Fade duration="4000">
            <img className="w-full" src={TransparentCircleTwo}/>
          </Fade>
        </div>

        <div className="bottom-12 right-4 absolute w-16 md:w-60 z-30">
          <Fade duration="6000">
            <img className="w-full" src={TransparentCircleThree}/>
          </Fade>
        </div>

        <div className="bottom-12 right-4 absolute w-12 md:w-40 z-40">
          <Fade duration="8000">
            <img className="w-full" src={TransparentCircleFour}/>
          </Fade>
        </div>
       
      </div>

      <div className="child sm:text-center md:text-left flex justify-center items-center  relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2">
          <div></div>
          <div className="text-white font-heading">
            <h3 className="text-5th-yellow text-xl lg:text-3xl pb-4">We know</h3>
            <h2 className="text-4xl lg:text-6xl pb-6">EVERYTHING CAN ALWAYS BE BETTER <span className="text-5th-pink">.</span></h2>
            <p className="text-md lg:text-xl font-lower">
            We believe in holding ourselves to the highest standards and pushing 
            boundaries to create products with an exceptional digital presence.<br/><br/>
            That’s why we use agile, iterative development techniques. 
            Constantly improving and incrementally getting closer to where we want to be. 
            Nothing is perfect first time, that’s why we keep going until we’ve made the best thing we can make.
            </p>
          </div>

        </div>
        <div className="bottom-0 left-0 absolute w-24 md:w-60 lg:w-96">
          <img className="w-full " src={YellowFull}/>
        </div>
      </div>


      <div className="child sm:text-center md:text-left flex justify-center items-center  relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2">
          <div className="text-white font-heading pt-12 lg:pt-0">
            <h3 className="text-5th-yellow text-xl lg:text-3xl pb-4">We offer</h3>
            <h2 className="text-4xl lg:text-6xl pb-6">TECHNICAL SKILL WITH A FRIENDLY FACE <span className="text-5th-pink">.</span></h2>
            <p className="font-lower text-md lg:text-xl">
              We know our React from our Wordpress, but we won’t tie you up in technical jargon when creating something together. 
              We co-design our products together and walk the path with you to make something different.<br/><br/>
              We’ve used lots of different technologies but we are tech-agnostic, we believe in using a variety of software and are committed to learning new things. It doesn’t matter what you want to build your digital platform in, we can handle it.<br/><br/>
            </p>
          </div>
          <div>

          </div>

        </div>

        <div className="top-0 right-0 absolute w-16 md:w-60 lg:w-96">
          <img className="w-full" src={CyanFull}/>
        </div>
        
      </div>

      <div className="child mx-auto relative text-left w-5/6 block py-16 md:py-24 lg:py-36">
          <p className="text-white text-xl font-heading pt-16 font-lower">Just in case you aren’t sure here’s a list of the technologies we’ve used on recent projects:</p>
          <div className=" py-12 grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 gap-8">
              <img className="mx-auto h-16" src={HTMLlogo}/>
              <img className="mx-auto h-16" src={csslogo}/>
              <img className="mx-auto h-16" src={jslogo}/>
              <img className="mx-auto h-16" src={wordpresslogo}/>
              <img className="mx-auto h-16" src={reactlogo}/>
              <img className="mx-auto h-16" src={gatsbylogo}/>
              <img className="mx-auto h-16" src={drupallogo}/>
              <img className="mx-auto h-16" src={tailwindlogo}/>
              <img className="mx-auto h-16" src={webflowlogo}/>
            </div>
      </div>


      <div className="child sm:text-center md:text-left flex justify-center items-center  relative text-left">
        <div className="w-5/6 md:grid md:grid-cols-2">
          <div className="text-white font-heading">
            <h2 className="text-4xl lg:text-6xl pb-6 pt-12">OUR PARTNERS <span className="text-5th-pink">.</span></h2>
           

            <div className=" py-4 lg: py-12 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              <img className="mx-auto h-20" src={portview}/>
              <img className="mx-auto h-20" src={bcc}/>
              <img className="mx-auto h-20" src={publichealth}/>
              <img className="mx-auto h-20" src={derry}/>
              <img className="mx-auto h-20" src={creativeeu}/>
              <img className="mx-auto h-20" src={wcc}/>
              <img className="mx-auto h-20" src={ancienteast}/>
             
            </div>
          </div>
          <div>

          </div>

        </div>

        <div className="top-0 right-0 absolute w-16 md:w-60 lg:w-96">
          <img className="w-full" src={WhiteMoon}/>
        </div>
        
      </div>

      <footer className="snap-always snap-center p-4 bg-5th-grey sm:p-6 my-6 lg:my-16 mx-auto w-5/6 font-lower">
            <div className="md:flex md:justify-between items-center">
                <div className="mb-6 md:mb-0">
                    <Link to="/" className="flex items-center">
                        <img src={Logo} className="h-36" alt="5th Strand Logo"/>
                    </Link>
                </div>
                <div className="mb-6 md:mb-0">
                  <h1 className="text-white text-2xl sm:text-3xl md:text-4xl leading-relaxed font-bold ">5th Strand<br/><span className='font-normal leading-relaxed'>Believers in Change</span></h1>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:gap-8">
                   
                   
                    <div>
                        <h2 className="mb-6 text-xs font-semibold text-white uppercase">Legal</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                            </li>
                           
                        </ul>
                    </div>

                    <div>
                        <h2 className="mb-6 font-semibold text-white uppercase text-xs">Contact</h2>
                        <ul className="text-white text-xs">
                            <li className="mb-4">
                                <a href="mailto:studio@5thstrand.com" className="hover:underline text-xs">studio@5thstrand</a>
                            </li>
                            <li>
                                <a href="tel:+447754078144" className="hover:underline">Tel: 077 540 78144</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8"/>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <span className="text-sm text-white sm:text-center dark:text-gray-400">© 2022 <a href="" className="hover:underline">5th Strand USI</a>. All Rights Reserved.
                    </span>
                    
                </div>
        </footer>
    
    </main>
    </Layout>
  )
}

export default IndexPage

export function Head() {
  return (
    <>
      <title>5th Strand</title>
      <meta name="description" content="5th Strand makes digital transformations for cultural venues, historic locations and people." />
        <meta property="og:title" content="5th Strand" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.5thstrand.com/" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
    </>  
    )
}
